import { render, staticRenderFns } from "./NotSubscriber.vue?vue&type=template&id=2b1124de&"
import script from "./NotSubscriber.vue?vue&type=script&lang=js&"
export * from "./NotSubscriber.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1495992030/src/minhaloja-v2-international/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2b1124de')) {
      api.createRecord('2b1124de', component.options)
    } else {
      api.reload('2b1124de', component.options)
    }
    module.hot.accept("./NotSubscriber.vue?vue&type=template&id=2b1124de&", function () {
      api.rerender('2b1124de', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/WelcomePages/NotSubscriber.vue"
export default component.exports